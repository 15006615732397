import React, { useState, useEffect } from "react"
import { useAuth } from "../authContext"
import { useLocation } from "react-router-dom"

function SyftForm() {
  const { currentUser } = useAuth()
  const [startMeal, setStartMeal] = useState(0)
  const [numMeals, setNumMeals] = useState(1)
  const [theModel, setTheModel] = useState(
    "jumpstart-dft-meta-textgeneration-llama-2-7b"
  )
  const [thePrompt, setThePrompt] = useState("")
  const [apiResponse, setApiResponse] = useState("")
  const [timer, setTimer] = useState(0)
  const [isSending, setIsSending] = useState(false)

  const [maxNewTokens, setMaxNewTokens] = useState(64)
  const [topP, setTopP] = useState(0.2)
  const [topK, setTopK] = useState(1)
  const [earlyStopping, setEarlyStopping] = useState(false)
  const [do_sample, setDo_sample] = useState(true)
  const [temperature, setTemperature] = useState(1.2)
  const [no_repeat_ngram_size, setNo_repeat_ngram_size] = useState(3)
  const [stop, setStop] = useState([])
  const [returnFullText, setReturnFullText] = useState(false)
  const [systemPrompt, setSystemPrompt] = useState("Respond as a Dietitian")
  const [endpointStatuses, setEndpointStatuses] = useState({})
  const location = useLocation()

  useEffect(() => {
    if (location.state && location.state.theModel) {
      setTheModel(location.state.theModel)
    }
  }, [location])

  useEffect(() => {
    // Fetch endpoint statuses when the component mounts
    async function fetchEndpointStatuses() {
      try {
        const response = await fetch(
          "https://api.syfthealth.app/sandbox/model-status"
        )
        const data = await response.json()
        setEndpointStatuses(data)
      } catch (error) {
        console.error("Error fetching endpoint statuses:", error)
      }
    }

    fetchEndpointStatuses()
  }, []) // Empty dependency array means this useEffect runs once when component mounts

  useEffect(() => {
    let timerId
    if (isSending) {
      timerId = setInterval(() => {
        setTimer((prevTime) => prevTime + 1)
      }, 1000)
    } else {
      clearInterval(timerId)
      //   setTimer(0)
    }
    return () => clearInterval(timerId) // Clear interval when component unmounts
  }, [isSending])

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsSending(true)
    setTimer(0)
    const formData = {
      userId: currentUser.id, // Pass user's ID
      startMeal: parseInt(startMeal),
      numMeals: parseInt(numMeals),
      theModel,
      thePrompt,
      maxNewTokens: parseInt(maxNewTokens),
      topP: parseFloat(topP),
      temperature: parseFloat(temperature),
      returnFullText,
      systemPrompt,
      topK: parseInt(topK),
      earlyStopping,
      do_sample,
      no_repeat_ngram_size: parseInt(no_repeat_ngram_size),
      stop: stop.split(",").map((s) => s.trim()),
    }

    try {
      const response = await fetch("https://api.syfthealth.app/sandbox/syft", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      })
      const data = await response.json() //.json()
      console.log(data)
      //   setApiResponse(JSON.stringify(data, null, 2)) // Pretty print the JSON data
      setApiResponse(data) // Pretty print the JSON data
    } catch (error) {
      console.error("There was an error submitting the form:", error)
    }
    setIsSending(false)
  }

  const formStyles = {
    // boxSizing: "border-box",
    // padding: "20px",
    // borderRadius: "5px",
    // boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    // margin: "40px auto",
  }

  const inputStyles = {
    boxSizing: "border-box", // use border-box so padding and border are included in the width
    padding: "10px",
    width: "100%",
    marginBottom: "10px",
    borderRadius: "5px",
    border: "1px solid #ddd",
  }
  const promptStyles = {
    boxSizing: "border-box", // use border-box so padding and border are included in the width
    padding: "10px",
    width: "100%",
    marginBottom: "10px",
    borderRadius: "5px",
    border: "1px solid #ddd",
  }

  const buttonStyles = {
    padding: "10px 20px",
    backgroundColor: isSending ? "#eee" : "rgba(0,0,0,0.3)", // Change color when sending
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: isSending ? "not-allowed" : "pointer", // Change cursor when sending
  }

  const leftColumn = {
    boxSizing: "border-box", // use border-box so padding and border are included in the width
    flex: "0 0 200px", // left column will be 200px wide
    flexBasis: "200px", // IE 10-11 fallback
    width: "200px", // fixed width for left column
  }

  const rightColumn = {
    padding: "0 0 0 20px",
    boxSizing: "border-box", // use border-box so padding and border are included in the width
    flex: 1, // make right column take up the remaining space
  }

  const containerStyles = {
    fontSize: "12px",
    boxSizing: "border-box", // use border-box so padding and border are included in the width
    width: "100%", // full width
    display: "flex", // set display to flex
    flexDirection: "row", // columns next to each other
    "@media (maxWidth: 768px)": {
      // media query for viewports less than 768px wide
      flexDirection: "column", // stack columns on top of each other
    },
  }

  return (
    <div>
      <form onSubmit={handleSubmit} style={formStyles}>
        <div style={containerStyles} className="input-container">
          <div style={leftColumn}>
            <div>
              <label>
                Model:
                <select
                  value={theModel}
                  onChange={(e) => setTheModel(e.target.value)}
                  style={inputStyles}
                >
                  <option
                    value="mistral-zephyr-7b-model"
                    disabled={
                      endpointStatuses["mistral-zephyr-7b-model"] !==
                      "InService"
                    }
                  >
                    Mistral Zephyr (7B)
                  </option>
                  <option
                    value="zephyr-7b-beta-4096inp-tokens"
                    disabled={
                      endpointStatuses["zephyr-7b-beta-4096inp-tokens"] !==
                      "InService"
                    }
                  >
                    Zephyr Long Context (7B)
                  </option>
                  <option
                    value="MistralLite"
                    disabled={endpointStatuses["MistralLite"] !== "InService"}
                  >
                    Mistral (7B)
                  </option>

                  {/* <option
                    value="syft-7B-baseline-model"
                    disabled={
                      endpointStatuses["syft-7B-baseline-model"] !== "InService"
                    }
                  >
                    Syft Base Model (7B)
                  </option>
                  <option
                    value="jumpstart-dft-meta-textgeneration-llama-2-7b"
                    disabled={
                      endpointStatuses[
                        "jumpstart-dft-meta-textgeneration-llama-2-7b"
                      ] !== "InService"
                    }
                  >
                    Base Model (7B)
                  </option>
                  <option
                    value="jumpstart-dft-meta-textgeneration-llama-2-7b-f"
                    disabled={
                      endpointStatuses[
                        "jumpstart-dft-meta-textgeneration-llama-2-7b-f"
                      ] !== "InService"
                    }
                  >
                    Chat Model (7B)
                  </option>
                  <option
                    value="jumpstart-dft-hf-llm-mistral-7b"
                    disabled={
                      endpointStatuses["jumpstart-dft-hf-llm-mistral-7b"] !==
                      "InService"
                    }
                  >
                    Mistral Chat Model (7B)
                  </option>
                  <option
                    value="zephyr-7b-beta"
                    disabled={
                      endpointStatuses["zephyr-7b-beta"] !== "InService"
                    }
                  >
                    Zephyr Chat Model (7B)
                  </option>
                  <option
                    value="jumpstart-dft-meta-textgeneration-llama-2-13b"
                    disabled={
                      endpointStatuses[
                        "jumpstart-dft-meta-textgeneration-llama-2-13b"
                      ] !== "InService"
                    }
                  >
                    Base Model (13B)
                  </option>
                  <option
                    value="jumpstart-dft-meta-textgeneration-llama-2-13b-f"
                    disabled={
                      endpointStatuses[
                        "jumpstart-dft-meta-textgeneration-llama-2-13b-f"
                      ] !== "InService"
                    }
                  >
                    Chat Model (13B)
                  </option>
                  <option
                    value="jumpstart-dft-meta-textgeneration-llama-2-70b"
                    disabled={
                      endpointStatuses[
                        "jumpstart-dft-meta-textgeneration-llama-2-70b"
                      ] !== "InService"
                    }
                  >
                    Base Model (70B)
                  </option>
                  <option
                    value="jumpstart-dft-meta-textgeneration-llama-2-70b-f"
                    disabled={
                      endpointStatuses[
                        "jumpstart-dft-meta-textgeneration-llama-2-70b-f"
                      ] !== "InService"
                    }
                  >
                    Chat Model (70B)
                  </option> */}
                </select>
              </label>
            </div>
            <div>
              <label>
                Max New Tokens:
                <input
                  type="number"
                  value={maxNewTokens}
                  onChange={(e) => setMaxNewTokens(e.target.value)}
                  style={inputStyles}
                />
              </label>
            </div>
            <div>
              <label>
                Top K:
                <input
                  type="number"
                  value={topK}
                  onChange={(e) => setTopK(e.target.value)}
                  style={inputStyles}
                />
              </label>
            </div>
            <div>
              <label>
                Top P:
                <input
                  type="range"
                  min="0.1"
                  max="0.9"
                  step="0.1"
                  value={topP}
                  onChange={(e) => setTopP(e.target.value)}
                  style={inputStyles}
                />
              </label>
              {topP}
            </div>
            <div>
              <label>
                Temperature:
                <input
                  type="range"
                  min="0.1"
                  max="1.9"
                  step="0.1"
                  value={temperature}
                  onChange={(e) => setTemperature(e.target.value)}
                  style={inputStyles}
                />
              </label>
              {temperature}
            </div>
            <div>
              <label>
                No Repeat Ngram Size:
                <input
                  type="number"
                  value={no_repeat_ngram_size}
                  onChange={(e) => setNo_repeat_ngram_size(e.target.value)}
                  style={inputStyles}
                />
              </label>
            </div>
            <div>
              <label>
                Early Stopping:
                <input
                  type="checkbox"
                  checked={earlyStopping}
                  onChange={() => setEarlyStopping(!earlyStopping)}
                />
              </label>
            </div>
            <div>
              <label>
                Do Sample:
                <input
                  type="checkbox"
                  checked={do_sample}
                  onChange={() => setDo_sample(!do_sample)}
                />
              </label>
            </div>
            <div>
              <label>
                Stop:
                <input
                  type="text"
                  value={stop}
                  onChange={(e) => setStop(e.target.value)}
                  style={inputStyles}
                />
              </label>
            </div>

            <div>
              <label>
                Start Meal:
                <input
                  type="number"
                  value={startMeal}
                  onChange={(e) => setStartMeal(e.target.value)}
                  style={inputStyles}
                />
              </label>
            </div>
            <div>
              <label>
                Number of Meals:
                <input
                  type="number"
                  value={numMeals}
                  onChange={(e) => setNumMeals(e.target.value)}
                  style={inputStyles}
                />
              </label>
            </div>
          </div>
          <div style={rightColumn}>
            {theModel.endsWith("-f") && (
              <div>
                <label>
                  System Prompt
                  <textarea
                    type="text"
                    value={systemPrompt}
                    onChange={(e) => setSystemPrompt(e.target.value)}
                    style={{ ...promptStyles, height: "100px" }}
                  />
                </label>
              </div>
            )}

            <div>
              <label>
                Prompt
                <textarea
                  value={thePrompt}
                  onChange={(e) => setThePrompt(e.target.value)}
                  style={{ ...promptStyles, height: "100px" }}
                />
              </label>
            </div>
            <div>
              <button type="submit" style={buttonStyles} disabled={isSending}>
                Send
              </button>
              {/* {isSending && ( */}
              <span style={{ marginLeft: "10px", whiteSpace: "nowrap" }}>
                Time elapsed: {timer} seconds
              </span>
              {/* )} */}
            </div>
          </div>
        </div>
      </form>
      <div className="response">
        <h3>API Response:</h3>
        {Array.isArray(apiResponse) &&
          apiResponse.map((description, index) => (
            <div key={index} className="meal_description">
              <pre>{description}</pre>
            </div>
          ))}
      </div>
    </div>
  )
}

export default SyftForm
